<template>
   <div>
    
    <input type="checkbox" id="teste"  >
    <label for="teste">teste</label>
   </div>
</template>
<style  scoped>
div label{
    background-color: #ff0;
}
input[type="checkbox"]:checked{
    background-color: blue;
    padding: 10px 25px;
}
</style>
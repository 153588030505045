<template>
  <div>
    <Login />
  </div>
</template>

<script>
import Login from "../components/login.vue"
export default {
  name: 'Home',
  components:{
    Login,
  } 
}
</script>
